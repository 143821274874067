import React from 'react';
import Link from '../utils/link';
import parse from 'html-react-parser';

const ContactDetails = ({address, email, phone, socials, title}) => {
  return (
    <section className="contact-details">
      <div className="contact-details__inner">
        <h2>{title}</h2>

        <div className="contact-details__content">
          <div className="contact-details__left">
            <Link>
              {phone.title}
            </Link>

            <Link>
              {email.title}
            </Link>
          </div>

          <div className="contact-details__right">
            <p>{parse(address)}</p>
          </div>
        </div>

        <div className="contact-details__socials">
          {
            socials.map((el, index) =>
              (
                <Link key={index} to={el.link.url}>
                  {el.link.title}
                </Link>
              ),
            )
          }
        </div>
      </div>
    </section>
  );
};

ContactDetails.defaultProps = {
  address: '415 Bay Street Brighton VIC 3186',
  email: {
    title: 'info@kervale.com',
  },
  phone: {
    title: '0417 863 507',
  },
  socials: [
    {
      link: {
        title: 'Instagram',
        url: 'https://www.instagram.com/kervale_group/?igshid=YmMyMTA2M2Y%3D',
      },
    },
    {
      link: {
        title: 'Facebook',
        url: 'https://www.facebook.com/profile.php?id=100075864354730',
      },
    },
    {
      link: {
        title: 'Linkedin',
        url: 'https://www.linkedin.com/company/kervale/',
      },
    }],
  title: ``,
};

export default ContactDetails;