import React, { Component } from 'react';
import Link from '../utils/link';
import { Logo } from './icons';

class Footer extends Component {

  render() {

    const { headerMenu, footerMenu } = this.props.data
    const half = Math.ceil(headerMenu?.menuItems?.nodes?.length / 2);
    const firstHalf = headerMenu?.menuItems?.nodes.slice(0, half)
    const secondHalf = headerMenu?.menuItems?.nodes.slice(half)

    return (
      <footer className="footer">
        <div className="footer__inner">
          <div className="footer__top">
            <div className="header__menu">
              <ul>
                {firstHalf?.map(({ uri, label }, index) => (
                  <li key={index}><Link to={uri}>{label}</Link></li>
                ))}
              </ul>
            </div>

            <div className="footer__logo-nav header__logo-nav">
              <Link to="/home/" title="Kervale" className="footer__logo header__logo">
                <Logo color={`#00164E`} />
              </Link>
              <nav className="header__nav">
              </nav>
            </div>

            <div className="header__menu header__right">
              <ul>
                {secondHalf?.map(({ uri, label }, index) => (
                  <li key={index}><Link to={uri}>{label}</Link></li>
                ))}
              </ul>
            </div>
          </div>

          <div className="footer__middle">
            <div className="contact">
              <div className="contact__info">
                <span><a href="+61417863507">0417 863 507</a></span>
                <span><a href="mailto:info@kervale.com">info@kervale.com</a></span>
              </div>

              <div className="contact__info">
                <span><a href="https://www.google.com/maps/place/415+Bay+St,+Brighton+VIC+3186/@-37.9058029,145.0034693,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad66920a5c7ece1:0xf5d3dd21ea02237d!8m2!3d-37.9058072!4d145.005658">415 Bay Street</a></span>
                <span><a href="https://www.google.com/maps/place/415+Bay+St,+Brighton+VIC+3186/@-37.9058029,145.0034693,17z/data=!3m1!4b1!4m5!3m4!1s0x6ad66920a5c7ece1:0xf5d3dd21ea02237d!8m2!3d-37.9058072!4d145.005658">Brighton VIC 3186</a></span>
              </div>
            </div>
          </div>

          <div className="footer__bottom">
            <div className="footer__terms">
              <p>© 2022 Kervale Group. All rights reserved.</p>

              <ul>
                {footerMenu?.menuItems?.nodes?.map(({ uri, label }, index) => (
                  <li key={index}><Link to={uri}>{label}</Link></li>
                ))}
              </ul>
            </div>

            <div className="footer__text">
              <div className="text">
                <p>In the spirit of reconciliation, Kervale respectfully acknowledges that every project developed by our team or to which we contribute exists on traditional Aboriginal lands.
We recognise the Traditional Custodians throughout Australia and their connection to land, sea and community. We pay our respects to their Elders, past and present.</p>
              </div>
            </div>

            <div className="footer__socials">
              <ul>
                <li><a href="https://www.facebook.com/profile.php?id=100075864354730">Facebook</a></li>
                <li><a href="https://instagram.com/kervale_group?igshid=YmMyMTA2M2Y=">Instagram</a></li>
                <li><a href="https://www.linkedin.com/company/kervale/">Linkedin</a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
