import React, { Component } from 'react';
import Link from '../utils/link';
import ContactDetails from './contact-details';

import { Logo } from './icons';

class Header extends Component {

  state = {
    offCanvas: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false });
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas });
  }

  render() {
    let { offCanvas } = this.state;

    const { headerMenu } = this.props.data
    const half = Math.ceil(headerMenu?.menuItems?.nodes?.length / 2);
    const firstHalf = headerMenu?.menuItems?.nodes.slice(0, 2)
    const secondHalf = headerMenu?.menuItems?.nodes.slice(half)

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    };

    let headerClass = 'header';
    if (offCanvas) headerClass += ' header--off-canvas-active';

    return (
      <>
        <header className={headerClass}>
          <div className="header__wrapper">
            <div className="header__inner">

              <div className="header__menu header__left">
                <ul>
                  {firstHalf?.map(({ uri, label }, index) => (
                    <>
                      <li key={index}>
                        <Link to={uri}>{label}</Link>
                        {label === 'About' &&
                          <div className='dropdown'>
                            <ul>
                              <li><Link to='/about/'>About us</Link></li>
                              <li><Link to='/about/#foundation'>Foundation</Link></li>
                              <li><Link to='/about/#partners'>Collaborators</Link></li>
                            </ul>
                          </div>
                        }
                      </li>
                    </>
                  ))}
                </ul>
              </div>

              <div className="header__logo-nav">
                <Link to="/home/" title="Kervale" className="header__logo" {...props}>
                  <Logo color={`#00164E`} short={this.state.short} />
                </Link>
                <nav className="header__nav">
                </nav>
              </div>

              <div className="header__menu header__right">
                <ul>
                  {secondHalf?.map(({ uri, label }, index) => (
                    <li key={index}><Link to={uri}>{label}</Link></li>
                  ))}
                </ul>
              </div>

              <button onClick={this._toggleOffCanvas} className="header__hamburger">
                {offCanvas ? <span>CLOSE</span> : <span>MENU</span>}
              </button>
            </div>
          </div>

          <div className="header__padding"></div>
        </header>

        <div className={`off-canvas ${offCanvas && 'active'}`}>
          <ul className="off-canvas__menu">
            {headerMenu?.menuItems?.nodes?.map(({ uri, label }, index) => (
              <li key={index} {...props}><Link to={uri}>{label}</Link></li>
            ))}
          </ul>

          <ContactDetails />
        </div>
      </>
    );
  }
}

export default Header;
